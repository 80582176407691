/*
 * Copyright 2024 LLC Campus.
 */

body, html {
    background: #111111;
    max-width: 100%;
    overflow-x: hidden;
}

.promocode-catalog {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    margin: 0;
    height: auto;
    padding: 20px;
}

.promocode-catalog-header {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
}

.promocode-catalog-header-title {
    width: 100%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    display: flex;
    letter-spacing: -0.408px;
    color: #e7eef2;
}

.promocode-catalog-header-list {
    padding-left: 30px;
    width: 100%;
    color: #e7eef2;
    margin: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.408px;
}

.promocode-grid {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    width: 100%;
}

.no-promocodes-message {
    width: 100%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    display: flex;
    letter-spacing: -0.408px;
    color: #e7eef2;
}

.email-input-container {
    display: flex;
    flex-direction: column;
    padding: 0 16px;
    width: 100%;
    align-items: stretch;
}

.email-input {
    height: 46px;
    background: #262626;
    border-radius: 8px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: #AEADB3;
    border: none;
    margin-bottom: 10px;
    padding-left: 10px;
}

.email-input.invalid {
    border: 1px solid red;
}

.error-message {
    color: red;
    font-size: 14px;
    margin-bottom: 10px;
}

.purchase-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 46px;
    gap: 10px;
    padding: 0px 32px;
    border: none;
    border-radius: 24px;
    background-color: #52ADCF;
    color: white;
    cursor: pointer;
    font-size: 1em;
    align-self: center;
    transition: background-color 0.3s;
    margin-bottom: 20px;
}

.purchase-button:hover {
    background-color: #459bb5;
}

.purchase-button:disabled,
.purchase-button.disabled {
    background-color: #7a7a7a;
    cursor: not-allowed;
}

.support-text {
    color: #E7EEF2;
    font-family: 'Inter';
    font-size: 14px;
    line-height: 20px;
}

.support-text ul {
    list-style-type: disc;
    padding-left: 20px;
}

.support-text li {
    margin-bottom: 10px;
}

.support-text a {
    color: #52ADCF;
    text-decoration: none;
}

.support-text a:hover {
    text-decoration: underline;
}

#yookassa-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 1000;
    display: none;
    justify-content: center;
    align-items: center;
}

.hidden {
    display: none;
}

.promocode-info {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.408px;
    color: #e7eef2; /* Цвет текста, как у других элементов */
    margin-bottom: 20px; /* Отступ перед кнопкой */
}
