/*
 * Copyright 2024 LLC Campus.
 */

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #0a0a0a;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.loader-container {
    background-color: #262626;
    padding: 16px;
    border-radius: 10px;
    text-align: center;
    max-width: 80%;
    max-height: 80%;
    color: #E7EEF2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.loader-text {
    margin-top: 20px;
    font-size: 1.2em;
}

.error-container {
    background-color: #FF6347; /* Красный цвет для ошибки */
    padding: 16px;
    border-radius: 10px;
    text-align: center;
    max-width: 80%;
    max-height: 80%;
    color: #fff;
    margin-bottom: 20px; /* Отступ снизу */
}

.success-container {
    background-color: #262626;
    padding: 16px;
    border-radius: 10px;
    text-align: center;
    max-width: 80%;
    max-height: 80%;
    color: #E7EEF2;
}

.success-container h1 {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 2em; /* Увеличиваем размер заголовка */
}

.success-container p {
    margin: 6px;
    font-size: 1.1em; /* Увеличиваем размер текста */
}

.success-container p strong {
    font-size: 1.5em; /* Увеличиваем размер текста */
}

.return-button {
    display: inline-block;
    padding: 10px 20px;
    border-radius: 20px;
    background-color: #383838;
    color: #fff;
    text-decoration: none;
    font-size: 1.1em;
    transition: background-color 0.3s ease;
    margin-top: 20px; /* Увеличиваем отступ сверху */
}

.return-button:hover {
    background-color: #383838;
}

.activation-button {
    display: inline-block;
    padding: 10px 20px;
    border-radius: 20px;
    background-color: #52ADCF;
    color: #fff;
    text-decoration: none;
    font-size: 1.1em;
    transition: background-color 0.3s ease;
    margin-top: 20px; /* Увеличиваем отступ сверху */
}

.activation-button:hover {
    background-color: #52ADCF;
}

.copy-icon {
    cursor: pointer;
    margin-left: 8px;
    color: #52ADCF;
}
