/*
 * Copyright 2024 LLC Campus.
 */

.promocode-card {
    border-radius: 24px;
    padding: 16px;
    background: #262626;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    box-sizing: border-box;
}

.promocode-card-content {
    width: 100%;
}

.promocode-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.promocode-name {
    margin: 0;
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    color: #E7EEF2;
}

.promocode-price {
    margin: 0;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #E7EEF2;
    flex-shrink: 0;
}

.promocode-price span {
    margin-right: 10px;
}

.promocode-card.selected {
    background: #262626;
    border: 1px solid #52ADCF;
}